import { useState } from "react";
import Address, { isAddressValid } from "../lib/components/govuk-frontend/Address";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { Details } from "../lib/components/govuk-frontend/Details";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import { Label } from "../lib/components/govuk-frontend/Label";
import { Input } from "../lib/components/govuk-frontend/Input";
import { RadioButtons } from "../lib/components/govuk-frontend/RadioButtons";

NewCompanyAddress.hasStarted = data => {
    return data?.useSnfAddress || data?.useOwnAddress;
}

export const isEmailValid = (email) => {
    return !!(String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
};

NewCompanyAddress.isValid = data => {
    return (data?.useSnfAddress && isEmailValid(data?.RegisteredEmailAddress))
        || (data?.useOwnAddress && isAddressValid(data?.RegisteredOfficeAddress, false) && isEmailValid(data?.RegisteredEmailAddress));
}

export default function NewCompanyAddress({ data, setData, onComplete }) {

    const [showErrors, setShowErrors] = useState();

    const onSelect = (_, i) => {
        setData({
            useSnfAddress: i === 0,
            useOwnAddress: i === 1
        });
    }

    const validate = () => {
        const email = data?.RegisteredEmailAddress?.toLowerCase().replaceAll(" ", "") || "";
        if (email !== data?.RegisteredEmailAddress) {
            setData({ RegisteredEmailAddress: email });
        }
        if (NewCompanyAddress.isValid(data)) {
            return true;
        }
        setShowErrors(new Date().getTime());
        return false;
    }

    return (
        <>
            <Heading>Office address</Heading>
            <RadioButtons
                options={[
                    "Use our registered office address service",
                    "Enter your own address details"
                ]}
                checkedIndex={data.useSnfAddress ? 0 : data.useOwnAddress ? 1 : null}
                onSelect={onSelect}
                requiredMessage="Please choose an option"
                showErrors={showErrors}
            />
            <Details
                summary="Help me to choose"
                body={(
                    <>
                        <b>Registered office address service</b>
                        <br /><br />
                        The registered office address is the official address of your company and will appear on the public record at Companies House.
                        In addition, all formal mail from Companies House and HMRC is sent to this address.
                        <br /><br />
                        If you wish to keep your residential address private, or if you don't have an address in the UK
                        you can use the free registered office service provided by us, and we'll assign one of our addresses to your company.
                    </>
                )}
            />

            {!!data.useSnfAddress && (
                <>
                    <Label>We'll assign this address to your company</Label>
                    <Hint>
                        <p>
                            <SnfAddress />
                        </p>
                    </Hint>
                    <Details
                        summary={"What is " + c0000}
                        body={c0000 + " will be replaced with your unique sub-address once you complete your application. Don't start using this address yet since this is just a placeholder for now. Your real registered office address will be sent to you automatically right after your company is established. Which is in 24 hours after you complete this wizard."}
                    />
                </>
            )}
            {!!data.useOwnAddress && (
                <>
                    <Address
                        title="REGISTERED OFFICE ADDRESS"
                        value={data.RegisteredOfficeAddress}
                        onChange={x => setData({ RegisteredOfficeAddress: x })}
                        showErrors={showErrors}
                        withCounty={false}
                    />
                </>
            )}
            <>
                <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                    <Label>What is your registered email address?</Label>
                </legend>
                <Input
                    smallLabel label="Email"
                    value={data?.RegisteredEmailAddress}
                    onChange={x => {
                        setData({ RegisteredEmailAddress: x.replaceAll(" ", "") })
                    }}
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    requiredMessage="Please enter a valid email address"
                    isInvalid={!isEmailValid(data?.RegisteredEmailAddress)}
                    showErrors={showErrors}
                />
            </>
            <ContinueButton data={data} validate={validate} onContinue={onComplete} />
        </>
    );
}

const c0000 = "C-0000";

export function SnfAddress() {
    return (
        <>
            SnF <code style={{ color: "pink" }}>{c0000}</code>, York House<br />
            18 York Road, Maidenhead<br />
            SL6 1SF<br />
            United Kingdom<br />
        </>
    );
}